import React from "react";
import './Plans.css';
import checkmarkIcon from './Vector.png';



type PlanProperties = {
    title: string;
    description: string;
    price: any;
    features: string[];
    buttonText: string;
    style?: React.CSSProperties;
    NotFeatureIndices: number[];
    titleStyle?: React.CSSProperties;
    buttonStyle?: React.CSSProperties;
    buttonTextStyle?: React.CSSProperties;
    onButtonClick?: () => void;
};
const Plan: React.FC<PlanProperties> = ({title, description, price, features, buttonText,
    NotFeatureIndices, titleStyle, buttonStyle, buttonTextStyle, onButtonClick}) => {
    return (
        <div className = 'plans-wrapper'>
            <div className = 'plan-container'>
                <h2 className = 'plan-title' style={titleStyle}>{title}</h2>
                <p className="plan-description">{description}</p>
                <p className="plan-price">{price}</p>
                <ul className='plan-features'>
                    {features.map((feature, index) => (
                        <li key={index} className={NotFeatureIndices.includes(index) ? 'no-icon' : ''} style={{
                            backgroundImage: NotFeatureIndices.includes(index) ? 'none' : `url(${checkmarkIcon})`,
                        }}>
                            {feature}
                        </li>
                    ))}
                </ul>
                <div className="divider"></div>

                <button className="plan-button" style={buttonStyle} onClick={onButtonClick}>
                    <span style={buttonTextStyle}>{buttonText}</span>
                </button>

                
            </div>
        </div>


    );
};

export default Plan;
