import { useState } from "react";
import "./ToggleSwitch.css";


interface ToggleSwitchProps {
    setBillingPeriod: (period: 'annually' | 'monthly') => void;
}


const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ setBillingPeriod }) => {
  
    const [isAnnual, setIsAnnual] = useState(true);
     const handleToggle = (period: 'annually' | 'monthly') => {
      setIsAnnual(period === 'annually');
      setBillingPeriod(period);
    };
     return (
      <div className="toggle_Switch">
        <button
          className={`toggle_Button ${isAnnual ? 'toggle_Button_Active' : ''}`}
          onClick={() => handleToggle('annually')}
        >
          Annually
        </button>
        <button
          className={`toggle_Button ${!isAnnual ? 'toggle_Button_Active' : ''}`}
          onClick={() => handleToggle('monthly')}
        >
          Monthly
        </button>
      </div>
    );
  }
   export default ToggleSwitch;
