import React from "react";
import "./App.css";
import PricingPage from "./pages/PricingPage";
import { Outlet } from "react-router-dom";
import Navbar from "./components/Navbar";
import SubscriptionCheckout from "./pages/SubscriptionCheckout";

const App: React.FC = () => {
  return (
    <div>
      <Navbar />
      <Outlet />
      {/* <PricingPage /> */}
    </div>
  );
};

export default App;

// <div className="App">
//   <header className="App-header">

//     <img src={logo} className="App-logo" alt="logo" />

//     <a
//       className="App-link"
//       href="https://reactjs.org"
//       target="_blank"
//       rel="noopener noreferrer"
//     >
//       Learn React
//     </a>
//   </header>
// </div>
