import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ToggleSwitch from "../components/ToggleSwitch";
import "./PricingPage.css";
import Plan from "../components/Plans";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutPage from "./SubscriptionCheckout";

const stripePromise = loadStripe(
  "pk_test_51PStkWHMISCyv0WXNzTNfNtyTeT0iidEHlFGij0dynjCPfKJbIlE2aLdHG9FIda7gAxvnwMw3mPpdpqzKgXrDLSQ00nG52mApU"
);

interface PriceDetails {
  regular: string;
  discounted: string;
}

interface Prices {
  annually: {
    free: string;
    pro: PriceDetails;
    proPlus: string;
  };
  monthly: {
    free: string;
    pro: string;
    proPlus: string;
  };
}

type BillingPeriod = "annually" | "monthly";

const PricingPage: React.FC = () => {
  console.log("Stripe Key:", process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  const [billingPeriod, setBillingPeriod] = useState<BillingPeriod>("annually");
  const navigate = useNavigate();

  const prices: Prices = {
    annually: {
      free: "$0.00",
      pro: {
        regular: "$47.88",
        discounted: "$39.99",
      },
      proPlus: "Coming Soon...",
    },
    monthly: {
      free: "$0.00",
      pro: "$3.99",
      proPlus: "Coming Soon...",
    },
  };

  const currentPrices = prices[billingPeriod];
  const Pro_price_to_pay =
    billingPeriod === "annually"
      ? parseFloat(
          (currentPrices.pro as PriceDetails).discounted.replace("$", "")
        )
      : parseFloat((currentPrices.pro as string).replace("$", ""));

  const renderProPrice = (): string => {
    if (billingPeriod === "annually") {
      const proPrice = currentPrices.pro as PriceDetails;
      return `<span class="original-price">${proPrice.regular}</span> <span class="discounted-price">${proPrice.discounted}</span>`;
    }
    return currentPrices.pro as string;
  };

  const handleCheckout = async (amount: number) => {
    console.log("direct success");
    console.log("Checkout amount:", amount);
    try {
      console.log({
        price: parseFloat("amount"),
        email: "email",
        billingPeriod: billingPeriod,
      });
      const response = await fetch(
        // "http://localhost:3001/api/stripe/create-checkout-session",
        "https://circo-api.vercel.app/api/stripe/create-checkout-session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },

          body: JSON.stringify({ price: amount, billingPeriod: billingPeriod }),
        }
      );

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const { sessionId } = await response.json();
      const stripe = await stripePromise;

      if (stripe) {
        const { error } = await stripe.redirectToCheckout({ sessionId });
        if (error) {
          console.error("Stripe checkout error:", error);
        }
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };

  return (
    <div className="Pricing_Page">
      <div className="Header">Choose your right plan</div>
      <div className="Sub_Header">
        Choose the best plan for you or your business!
      </div>
      <ToggleSwitch setBillingPeriod={setBillingPeriod} />
      <div className="plans-container">
        {/* Free Plan */}
        <Plan
          title="Free"
          description="For you to share your business and personal info with others"
          price="Free"
          features={[
            "1 digital business card and QR code",
            "This is a placeholder message",
            "This is a placeholder message",
            "This is a placeholder message",
            "This is a placeholder message",
            "This is a placeholder message",
            "This is a placeholder message",
          ]}
          buttonText="Get started for free"
          NotFeatureIndices={[5, 6]}
          titleStyle={{ color: "black" }}
          buttonStyle={{
            backgroundColor: "white",
            border: "1px solid #E6E6E6",
          }}
          buttonTextStyle={{ color: "black" }}
          onButtonClick={() => handleCheckout(0.0)}
        />

        {/* Pro Plan */}
        <Plan
          title="Pro"
          description="For you to share your business and personal info with others"
          price={
            <span dangerouslySetInnerHTML={{ __html: renderProPrice() }} />
          }
          features={[
            "1 digital business card and QR code",
            "This is a placeholder message",
            "This is a placeholder message",
            "This is a placeholder message",
            "This is a placeholder message",
            "This is a placeholder message",
            "This is a placeholder message",
          ]}
          buttonText="Try 7 days free"
          NotFeatureIndices={[5, 6]}
          onButtonClick={() => handleCheckout(Pro_price_to_pay)}
        />

        {/* Pro Plus Plan */}
        <Plan
          title="Pro+"
          description="For you to share your business and personal info with others"
          price={
            <span className="coming-soon-price">{currentPrices.proPlus}</span>
          }
          features={[
            "1 digital business card and QR code",
            "This is a placeholder message",
            "This is a placeholder message",
            "This is a placeholder message",
            "This is a placeholder message",
            "This is a placeholder message",
            "This is a placeholder message",
          ]}
          buttonText="Try 7 days free"
          NotFeatureIndices={[5, 6]}
        />
      </div>
    </div>
  );
};

export default PricingPage;
