import React from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Navbar: React.FC = () => {
  const navigate = useNavigate();

  const handleShopClick = () => {
    navigate("/shop");
  };

  const handleGetStartedClick = () => {
    navigate("/get-started");
  };

  return (
    <div className="navbar">
      <div className="circo-logo"></div>
      <div className="right-container">
        <Link to="/home" className="navTexts">
          Home
        </Link>
        <Link to="/about" className="navTexts">
          About
        </Link>
        <Link to="/faq" className="navTexts">
          FAQ
        </Link>
        <Link to="/contact" className="navTexts">
          Contact
        </Link>
        <button className="shop-button" onClick={handleShopClick}>
          <span className="text">Shop</span>
        </button>
        <button className="Get-Started-button" onClick={handleGetStartedClick}>
          <span className="text">Get Started</span>
        </button>
      </div>
    </div>
  );
};

export default Navbar;
