import React from "react";
import Navbar from "../components/Navbar";
// import "./Home.css";

const Home: React.FC = () => {
  console.log("home");
  return <div className="home"></div>;
};

export default Home;
