import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);
const stripePromise = loadStripe(
  "pk_test_51PStkWHMISCyv0WXNzTNfNtyTeT0iidEHlFGij0dynjCPfKJbIlE2aLdHG9FIda7gAxvnwMw3mPpdpqzKgXrDLSQ00nG52mApU"
);

const SubscriptionCheckout: React.FC = () => {
  const [searchParams] = useSearchParams();
  const amount = searchParams.get("amount");
  const email = searchParams.get("email");
  const billingPeriod = searchParams.get("billingPeriod");

  useEffect(() => {
    if (amount && email) {
      const createCheckoutSession = async () => {
        try {
          const response = await fetch(
            "https://circo-api.vercel.app/api/stripe/create-checkout-session",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                price: parseFloat(amount),
                email: email,
                billingPeriod: billingPeriod,
              }),
            }
          );

          if (!response.ok) {
            throw new Error(
              `Network response was not ok: ${response.statusText}`
            );
          }

          const { sessionId } = await response.json();
          const stripe = await stripePromise;

          if (stripe) {
            const { error } = await stripe.redirectToCheckout({ sessionId });
            if (error) {
              console.error("Stripe checkout error:", error);
            }
          }
        } catch (error) {
          console.error("Error creating checkout session:", error);
        }
      };

      createCheckoutSession();
    } else {
      console.error("Amount is missing in the URL parameters");
    }
  }, [amount, billingPeriod]);

  return (
    <div>
      <div>Loading Checkout...</div>
    </div>
  );
};

export default SubscriptionCheckout;
